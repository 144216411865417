module.exports = {
    microsoftClientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID ,
    api: {
        baseUrl: process.env.REACT_APP_API_BASE_URL || "",
        endpoint: {
            register: process.env.REACT_APP_API_REGISTER || '/users/register',
            registerInvitedUser: process.env.REACT_APP_REGISTER_INVITED_USER || '/users/register-invited-user',
            login: process.env.REACT_APP_API_LOGIN || '/login',
            logout: '/logout',
            verifyUser: process.env.REACT_APP_API_VERIFY_USER || '/users/verify',
            getUserDetailsFromToken: '/users/get-user-details-from-token',
            resendVerificationCode: `/users/verify-code/generate`,
            resendMutliFactorAuthenticationCode:`/resend-authentication-code`,
            resentAuthenticationOtp: `/resend-authentication-otp`,
            microsoftServiceLogin: '/auth/microsoft',
            forgotPassword: `/users/forgot-password`,
            resetPassword: `/users/reset-password`,
            settings: 'users/settings',
            user: '/users',
            signin: '/signin',
            verifyOTP: '/authenticate-otp',
            setUserPin: '/users/set-pin',
            forgetPin: '/users/forget-pin'
        }
    }
}